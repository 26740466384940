import firebase from 'gatsby-plugin-firebase'

const isBrowser = typeof window !== 'undefined' && !window.STORYBOOK_ENV

if (isBrowser) {
    firebase.firestore().settings({ experimentalForceLongPolling: true, merge: true })
}

const submitFormBookAppointment = async (options) => {
    try {
        await firebase
            .firestore()
            .collection(options.firebaseCollection)
            .add({
                to: options.confirmationEmail,
                template: {
                    name: options.emailTemplate,
                    data: options.firebaseData
                }
            })

        // Book an appointment in Office Line
        const response = await fetch(`https://www.protectline.co.uk/web/appointment/bookUnassigned`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: options.olData
        })

        if (response.ok) {
            options.actions.setSubmitting(false)
        } else {
            console.error('Office Line book appointment error: ', response.error)
        }
    } catch (err) {
        console.error('Error:', err)
    }
}

export default submitFormBookAppointment
