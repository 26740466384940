import React, { useState } from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import * as Yup from 'yup'
import { postcodeRegEx } from '/src/functions/regEx'
import { formatHumanDateFromSingleValues, formatHumanAppointmentDateTime } from '/src/functions/timeFormatter'
import submitFormBookAppointment from '/src/functions/forms/submitFormBookAppointment'

const Wizard = loadable(() => import('/src/components/Form/FormWizard/Wizard'))

const StepAppointmentPicker = loadable(() => import('./StepAppointmentPicker'))
const StepBeneficiaryDetails = loadable(() => import('./StepBeneficiaryDetails'))
const StepTrusteeDetails = loadable(() => import('./StepTrusteeDetails'))
//const StepTrustType = loadable(() => import('./StepTrustType'))
const StepUserDetails = loadable(() => import('./StepUserDetails'))

const TrustAppointmentForm = ({ leadId, collectionId, appointmentDuration }) => {
    const leadIdForForm = leadId.plid || leadId.lid

    const [humanReadableDateTime, setHumanReadableDateTime] = useState('')
    const [slotFieldValue, setSlotFieldValue] = useState('')
    const [isSubmitted, setSubmitted] = useState(false)

    const successMessage = `<p><strong>Thank you for booking your Trust appointment with us!</strong></p><p>Your chosen timeslot has been confirmed for you, and a representative will call you at the assigned time:</p><p><strong>${humanReadableDateTime}</strong></p>`

    const validationSchemaStepAppointmentPicker = Yup.object({
        ref: Yup.string().required('Lead ID is required'),
        slot: Yup.string().required('Please choose a timeslot')
    })

    // const validationSchemaStepTrustType = Yup.object({
    //     trustType: Yup.string()
    //         .required('This field is required'),
    // })

    const validationSchemaStepUserDetails = Yup.object({
        name: Yup.string().required('Your name is required'),
        dobDay: Yup.string().required('Day is required'),
        dobMonth: Yup.string().required('Month is required'),
        dobYear: Yup.string().required('Year is required'),
        address: Yup.string(),
        postcode: Yup.string().matches(postcodeRegEx, 'Postcode is not valid. Please ensure you have added a space')
    })

    const validationSchemaStepTrusteeDetails = Yup.object({
        trusteeName: Yup.string().required('Trustee name is required'),
        trusteeEmail: Yup.string().email('Email address is not valid'),
        trusteePhone: Yup.string().max(15, 'Cannot be longer than 15 digits'),
        trusteeDobDay: Yup.string().required('Day is required'),
        trusteeDobMonth: Yup.string().required('Month is required'),
        trusteeDobYear: Yup.string().required('Year is required'),
        trusteeAddress: Yup.string(),
        trusteePostcode: Yup.string().matches(
            postcodeRegEx,
            'Postcode is not valid. Please ensure you have added a space'
        )
    })

    const validationSchemaStepBeneficiaryDetails = Yup.object({
        beneficiaryName: Yup.string().required('Beneficiary name is required'),
        beneficiaryDobDay: Yup.string().required('Day is required'),
        beneficiaryDobMonth: Yup.string().required('Month is required'),
        beneficiaryDobYear: Yup.string().required('Year is required'),
        beneficiaryAddress: Yup.string(),
        beneficiaryPostcode: Yup.string().matches(
            postcodeRegEx,
            'Postcode is not valid. Please ensure you have added a space'
        )
    })

    return (
        <Wizard
            initialValues={{
                ref: '',
                slot: '',
                name: '',
                dobDay: '',
                dobMonth: '',
                dobYear: '',
                address: '',
                postcode: '',
                //trustType: '',
                trusteeName: '',
                trusteeEmail: '',
                trusteePhone: '',
                trusteeDobDay: '',
                trusteeDobMonth: '',
                trusteeDobYear: '',
                trusteeAddress: '',
                trusteePostcode: '',
                beneficiaryName: '',
                beneficiaryDobDay: '',
                beneficiaryDobMonth: '',
                beneficiaryDobYear: '',
                beneficiaryAddress: '',
                beneficiaryPostcode: ''
            }}
            fieldValuesToUpdate={[
                { field: 'ref', value: leadIdForForm },
                { field: 'slot', value: slotFieldValue }
            ]}
            submitText={'Book now'}
            submitSuccessText={'Appointment booked!'}
            onSubmit={async (values, actions) => {
                // This is to use in the submission success message
                setHumanReadableDateTime(formatHumanAppointmentDateTime(values.slot))

                const firebaseData = {
                    createdAt: new Date(),
                    ref: values.ref,
                    slot: values.slot,
                    name: values.name,
                    dob: formatHumanDateFromSingleValues(values.dobDay, values.dobMonth, values.dobYear),
                    address: values.address,
                    postcode: values.postcode,
                    //trustType: values.trustType,
                    trusteeName: values.trusteeName,
                    trusteeEmail: values.trusteeEmail,
                    trusteePhone: values.trusteePhone,
                    trusteeDob: formatHumanDateFromSingleValues(
                        values.trusteeDobDay,
                        values.trusteeDobMonth,
                        values.trusteeDobYear
                    ),
                    trusteeAddress: values.trusteeAddress,
                    trusteePostcode: values.trusteePostcode,
                    beneficiaryName: values.beneficiaryName,
                    beneficiaryDob: formatHumanDateFromSingleValues(
                        values.beneficiaryDobDay,
                        values.beneficiaryDobMonth,
                        values.beneficiaryDobYear
                    ),
                    beneficiaryAddress: values.beneficiaryAddress,
                    beneficiaryPostcode: values.beneficiaryPostcode
                }

                const olData = new URLSearchParams({
                    customerReferralCode: values.ref,
                    collectionId: collectionId,
                    startTime: values.slot,
                    durationMinutes: appointmentDuration
                })

                const submitOptions = {
                    actions: actions,
                    firebaseCollection: 'trust-appointment-form',
                    firebaseData: firebaseData,
                    confirmationEmail: process.env.GATSBY_TRUSTS_EMAIL_ADDRESS,
                    emailTemplate: 'trust-appointment-form-template',
                    olData: olData
                }

                setSubmitted(true)

                await submitFormBookAppointment(submitOptions)
            }}
            isSubmitted={isSubmitted}
            successMessage={successMessage}
        >
            <StepAppointmentPicker
                leadId={leadId}
                collectionId={collectionId}
                appointmentDuration={appointmentDuration}
                setSlotFieldValue={setSlotFieldValue}
                validationSchema={validationSchemaStepAppointmentPicker}
            />

            <StepUserDetails validationSchema={validationSchemaStepUserDetails} />

            {/* <StepTrustType
                validationSchema={validationSchemaStepTrustType}
            /> */}

            <StepTrusteeDetails validationSchema={validationSchemaStepTrusteeDetails} />

            <StepBeneficiaryDetails validationSchema={validationSchemaStepBeneficiaryDetails} />
        </Wizard>
    )
}

TrustAppointmentForm.propTypes = {
    /**
     * Lead ID from URL parameter
     */
    leadId: PropTypes.shape({
        plid: PropTypes.string,
        lid: PropTypes.string
    }),
    /**
     * Appointment type (numerical value)
     */
    collectionId: PropTypes.number,
    /**
     * Appointment duration (numerical value)
     */
    appointmentDuration: PropTypes.number
}

TrustAppointmentForm.defaultProps = {
    leadId: {
        plid: 'TTEL8BF3SZ',
        lid: '109365'
    },
    collectionId: 2092,
    appointmentDuration: 30
}

export default TrustAppointmentForm
